'use client'

import { useEffect, useState } from 'react'
import { getURLSearchParams, track } from '../utils/api'

const Track = ({ eventName, eventParams }: { eventName: string; eventParams?: { [key: string]: any } }) => {
    const eventParamsJSON = eventParams === undefined ? undefined : JSON.stringify(eventParams)
    const [ignore, setIgnore] = useState(false)
    const urlSearchParams = getURLSearchParams()
    useEffect(() => {
        // make sure we only call track once
        if (ignore) {
            return
        }
        setIgnore(true)
        const utmParams: any = {}
        if (urlSearchParams?.has('utm_source')) {
            utmParams['utm_source'] = urlSearchParams.get('utm_source')
        }
        if (urlSearchParams?.has('utm_medium')) {
            utmParams['utm_medium'] = urlSearchParams.get('utm_medium')
        }
        if (urlSearchParams?.has('utm_campaign')) {
            utmParams['utm_campaign'] = urlSearchParams.get('utm_campaign')
        }
        if (urlSearchParams?.has('utm_content')) {
            utmParams['utm_content'] = urlSearchParams.get('utm_content')
        }
        const eventParams = eventParamsJSON === undefined ? undefined : JSON.parse(eventParamsJSON)
        let trackParams: any = undefined
        if (Object.keys(utmParams).length) {
            trackParams = utmParams
        }
        if (eventParams !== undefined && Object.keys(eventParams).length) {
            trackParams = { ...trackParams, ...eventParams }
        }
        // call track
        track(eventName, trackParams)
    }, [eventName, eventParamsJSON, ignore, urlSearchParams])
    return null
}

export default Track
